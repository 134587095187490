import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom'
import { Text, Link as ChakraLink, Stack, Flex, Menu, MenuButton, MenuList, MenuItem, MenuDivider, VStack } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';


const Footer: React.FC = () => {
    return (
        <>
            <Flex paddingTop={10} paddingBottom={10} direction='column' justifyContent='space-between' alignItems='center' w='100%'>
                <ChakraLink as={ReactRouterLink} to='/' mt={5}>
                    <Text fontSize='4xl' as='mark'>GG</Text>
                </ChakraLink>
                <VStack direction='row' spacing={6} mt={5} align='center'>
                    <ChakraLink href='mailto:thisisgurpal@gmail.com' isExternal fontSize='30px'>
                        thisisgurpal@gmail.com
                    </ChakraLink>
                    <ChakraLink as={ReactRouterLink} to='/blogs'>
                        Blogs
                    </ChakraLink>
                    <ChakraLink as={ReactRouterLink} to='/products'>
                        Products
                    </ChakraLink>
                    <ChakraLink href='https://github.com/thisisgurpal' isExternal>
                        GitHub
                    </ChakraLink>
                    <ChakraLink href='https://www.instagram.com/gurpalgohler/' isExternal>
                        Instagram
                    </ChakraLink>
                    <ChakraLink href='https://www.linkedin.com/in/gurpal-gohler' isExternal>
                        Linkedin
                    </ChakraLink>
                    <ChakraLink href='https://x.com/gurpalgohler' isExternal>
                        X
                    </ChakraLink>
                </VStack>
            </Flex>

        </>

    );
};

export default Footer;