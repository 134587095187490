import React from 'react';
import { Outlet } from 'react-router';
import { Box, ChakraProvider } from '@chakra-ui/react'
import Nav from './components/Nav';
import Footer from './components/Footer';

function App() {
  return (
    <ChakraProvider>
    <Box marginLeft={{ base: '5', sm: '10', lg: '20' }} marginRight={{ base: '5', sm: '10', lg: '20' }}>
    <Nav/>
    <Outlet/>
    <Footer/>
    </Box>
    
    </ChakraProvider>
  );
}

export default App;
