import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../components/Home";
import Projects from "../components/Project/Projects";
import PremierLeagueScrapeDash from "../components/Blog/IndividualBlogs/PremierLeagueScrapeDash";
import Blog from "../components/Blog/Blog";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {path: "", element: <Home />},

            {path: "products", element: <Projects />},

            {path: "blogs", element: <Blog />},
            {path: "blogs/premier-league-scrape-and-visualisation", element: <PremierLeagueScrapeDash />},
        ]
    }
])