// src/data/blogData.ts

export interface Project {
    id: number;
    title: string;
    description: string;
    techStack: Array<string>;
    tags: Array<string>;
    image: string;
    link: string;
  }
  
  export const projects: Project[] = [
    // {
    //   id: 1,
    //   title: "Making a dashboard from scraping the Premier League website.",
    //   description: "Making a dashboard from scraping the Premier League website.",
    //   techStack: ['Python', 'Tableau', 'SQL'],
    //   tags: ['Webscraping', 'Data Engineering', 'Data Analytics', 'Data Visualisation'],
    //   image: "https://res.cloudinary.com/dmbkzjwzq/image/upload/v1723925232/portfolio-main/Premier_League_Dashboard_Tableau_h4vc3x.jpg",
    //   link: "/projects/premier-league-scrape-and-visualisation",
    // }
  ];