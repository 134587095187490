// src/data/blogData.ts

export interface Blog {
    id: number;
    title: string;
    description: string;
    image: string;
    link: string;
}

export const blogs: Blog[] = [
    {
        id: 1,
        title: "Teaching Myself WebScraping using Python and Tableau.",
        description: "Web scraping the Premier League website and displaying the data using Tableau.",
        image: "https://res.cloudinary.com/dmbkzjwzq/image/upload/v1723925232/portfolio-main/Premier_League_Dashboard_Tableau_h4vc3x.jpg",
        link: "/blogs/premier-league-scrape-and-visualisation",
    },
];
